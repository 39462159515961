

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const InventoryTransfersList = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) return;
    console.log(accessToken)

    $('#inventory-transfers-table').DataTable({
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        ajax: {
            url: '/api/warehouse/detail/warenhouse-inventory-transfers/',

            headers: {
                'Authorization': `Token ${accessToken}`
            },
            dataSrc: function(json) {
                console.log(json);
                return json.data;
            },
        },
        columns: [
            {
                "data": "transfer_number",
                "title": "Übertragungs-Nr."
            },
            {
                "data": "transfer_date",
                "title": "Datum"
            },
            {
                "data": "source_location_name",
                "title": "Quelllager"
            },
            {
                "data": "target_location_name",
                "title": "Ziellager"
            },
            {
                "data": "transferred_by",
                "title": "Übertragen von"
            },
        ],
        language: {
            "decimal": ",",
            "emptyTable": "Keine Daten verfügbar",
            "info": "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty": "0 bis 0 von 0 Einträgen",
            "infoFiltered": "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu": "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing": "Bitte warten...",
            "search": "Suchen:",
            "zeroRecords": "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });

}
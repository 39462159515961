

import AlertHandler from "../../../../assets/js/modules/alertHandler";
import FetchHandler from "../../../../assets/js/assets/FetchHandler";

export default class InventoryTransfersCreate{
    constructor(){
        this.events();
    }
    events(){
        const inventoryTransferForm = document.getElementById('inventoryTransferForm');
        if(!inventoryTransferForm) return;
        inventoryTransferForm.addEventListener('submit', this.inventoryTransfersCreate.bind(this))
        $(inventoryTransferForm).find('select[name="movement"]').on('select2:select', function(e) {
            const selectedOption = $(e.target).find(':selected');
            const productId = selectedOption.val();
            this.inventoryTransfersForm(productId);
        }.bind(this));
    }
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    async inventoryTransfersCreate(event){
        event.preventDefault();
        try{
            const { status, body } = await FetchHandler.sendFormData(event.target,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
            });
            if (status === 200 && body.success) {
                AlertHandler.showAlert(body.message, 'success');
                setTimeout(() => {
                    //window.location.href = '/warehouse/list/';
                }, 300);
            } else {
                console.log(body)
                AlertHandler.showAlert(body.message || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error){
            console.error('InventoryTransfersCreate error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        }
    }

    // Befüllt das Quell-Lagerplatz-Dropdown im Transfer-Formular mit dem aktuellen Standort des Inventars.
    async inventoryTransfersForm(id){
        const accessToken = this.getCookie('drfToken');
        if (!accessToken) return;
        const options = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`,
        };
        const url = `/api/warehouse/detail/${id}/warenhouse-inventory-transfers-form/?id=${id}`;
        const response = await FetchHandler.getData(url, options);
        const data = await response.json();
        const sourceLocationSelect = $("#id_source_location");
        sourceLocationSelect.empty();
        if (data.storage) {
            const option = new Option(`Lagerplatz: ${data.storage_name}`, data.storage, true, true);
            sourceLocationSelect.append(option);
        }
        sourceLocationSelect.trigger("change");
    }
}
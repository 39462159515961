

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


export const inventoryOutputs = () => {

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    $('#inventory-outputs').DataTable({
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        ajax: {
            url: '/api/warehouse/detail/warenhouse-inventory-outputs/',
            headers: {
                'Authorization': `Token ${accessToken}`
            },
            dataSrc: function(json) {
                console.log(json);
                return json.data;
            },
        },
        columns: [
            { 
                data: "output_number", 
                title: "Ausgangsnummer" 
            },
            { 
                data: "issued_date", 
                title: "Ausgangsdatum", 
                render: function(data) {
                    const date = new Date(data);
                    return date.toLocaleDateString('de-DE') + ' ' + date.toLocaleTimeString('de-DE');
                }
            },
            { 
                data: "issued_by", 
                title: "Ausgegeben von" 
            },
            { 
                data: "stock_movement.inventory.product.designation", 
                title: "Produkt" 
            },
            { 
                data: "stock_movement.inventory.storage_location.name", 
                title: "Lagerplatz", 
                defaultContent: "-" 
            },
            { 
                data: "stock_movement.inventory.quantity", 
                title: "Bestandsmenge" 
            },
            { 
                data: "quantity", 
                title: "Menge" 
            },
            { 
                data: "delivered_quantity", 
                title: "Liefermenge" 
            },
            { 
                data: "remaining_quantity", 
                title: "Verbleibende Menge" 
            },
            { 
                data: "status", 
                title: "Status" 
            }
        ],
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });

}


import { ChangeHistory } from "../../../../assets/js/modules/changeHistory";

export default class StorageNodeChangeHistory{
    constructor(){
        this.changeHistoryContainer = document.querySelector('#change-history-container-storage-node');
        if(!this.changeHistoryContainer) return;
        this.event();  
    }
    event(){
        if (this.changeHistoryContainer) {
            const modelName = 'storagenode';
            const objectId = warenhouseIdVar;
            const fieldsToShow = [
                'storage_type', 
                'name', 
                'capacity'];
            const fieldLabels = {
                storage_type: 'Lagerplatztyp',
                name: 'Name',
                description: 'Beschreibung',
                capacity: 'Kapazität (m³)',
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-storage-node', fieldsToShow, fieldLabels);
        }
    }
}
import 'bootstrap';


//import addressSelection from './modules/addressSelection'
import genericModal from './modules/genericModal'
import genericEditModal from './modules/genericEditModal'
import genericDeleteModal from './modules/genericDeleteModal'
import dataTableListView from './modules/dataTableListView'

// Modal für Hauptkomponenten Rechnung Angebot Auftrag Lieferschein.
import modalsPostions from './common/SaveModalPostions';

// Klasse für das Handling von Angeboten, Rechnungen, Aufträgen und Lieferscheinen
import OfferInvoiceOrderDeliveryForm from './modules/OfferInvoiceOrderDeliveryForm';

document.addEventListener('DOMContentLoaded', function () {
    //const addressselection = new addressSelection(); 
    const genericmodal = new genericModal();
    const genericeditmodal = new genericEditModal();
    const genericdeletemodal = new genericDeleteModal();
    const datatablelistview = new dataTableListView();

    modalsPostions();

    let listMainElements = {};

    // Nur hinzufügen was existiert
    if (typeof invoicesIdVar !== 'undefined') {
        listMainElements['invoice'] = {
            'mainId': invoicesIdVar,
            'mainFormId': '#inoicesUpdateForm',
            'modalId': '#inventoryCheckModal',
            'confirmBtnClass': '.confirm-btn', 
            'productPositionUrl': invoiceProductPositionUrl, 
            'confirmUrl': invoicesConfirmUrl,
            'mainUrl': invoiceMainUrl,
            'dataContainer': 'dataContainerIncoice',
            'confirmDate': null,
            'designationNumber': null,
            'formInputNames': {
                'dates': [
                    'service_date_start',
                    'service_date_end',
                ],
                'select2': [
                    'skonto',
                    'payment_terms',
                    'customer'
                ],
                'inputs': [],
                'btnClass': [
                    'lock-class'
                ]
            },
            'buttonClasses':  [
                '.confirm-btn.lock-class',
                '.lock-class.email-button',
                '.lock-class.banking-btn',
                '.lock-class.storno-btn',
                '.lock-class.archive-btn'
            ]
        };
    }

    if (typeof offersIdVar !== 'undefined') {
        listMainElements['offer'] = {
            'mainId': offersIdVar,
            'mainFormId': '#offerUpdateForm',
            'modalId': '#inventoryCheckModal',
            'confirmBtnClass': '.confirm-btn',
            'productPositionUrl': offerProductPositionUrl,
            'confirmUrl': offerConfirmUrl,
            'mainUrl': offerMainUrl,
            'dataContainer': 'dataContainerOffer',
            'confirmDate': 'confirm-date',
            'designationNumber': 'offer-number',
            'cancelform': '#offersStronoForm',
            'formInputNames': {
                'select2': [
                    'customer'
                ],
                'dates': [
                    'valid_until',
                ],
                'inputs': [],
                'btnClass': [
                    'lock-class'
                ]
            },
            'buttonClasses':  [
                '.confirm-btn.lock-class',
                '.lock-class.email-button',
                '.lock-class.storno-btn',
                '.lock-class.archive-btn'
            ]
        };
    }

    if (typeof ordersIdVar !== 'undefined') {
        listMainElements['order'] = {
            'mainId': ordersIdVar,
            'mainFormId': '#ordersUpdateForm',
            'modalId': '#inventoryCheckModal',
            'confirmBtnClass': '.confirm-btn',
            'productPositionUrl': ordersProductPositionUrl,
            'confirmUrl': ordersConfirmUrl,
            'mainUrl': ordersMainUrl,
            'dataContainer': 'dataContainerOrder',
            'confirmDate': 'confirm-date',
            'designationNumber': 'orders-number',
            'cancelform': '#ordersStronoForm',
            'formInputNames': {
                'select2': [
                    'customer'
                ],
                'inputs': [],
                'btnClass': [
                    'lock-class',
                ]
            },
            'buttonClasses':  [
                '.confirm-btn.lock-class',
                '.lock-class.banking-btn',
                '.lock-class.storno-btn',
                '.lock-class.archive-btn',
                '.update-products.lock-class'
            ]
        };
    }

    if (typeof deliveryNoteIdVar !== 'undefined') {
        listMainElements['delivery'] = {
            'mainId': deliveryNoteIdVar,
            'mainFormId': '#deliveryNoteUpdateForm',
            'modalId': '#inventoryCheckModal',
            'confirmBtnClass': '.confirm-btn',
            'productPositionUrl': deliveryNoteProductPositionUrl,
            'confirmUrl': deliveryNoteConfirmUrl,
            'mainUrl': deliveryNoteMainUrl,
            'dataContainer': 'dataContainerDeliveryNote',
            'confirmDate': 'confirm-date',
            'designationNumber': 'delivered-number',
            'cancelform': '#DeliveryNoteForm',
            'formInputNames': {
                'select2': [
                    'customer'
                ],
                'inputs': [],
                'btnClass': [
                    'lock-class'
                ]
            },
            'buttonClasses':  [
                '.confirm-btn.lock-class',
                '.lock-class.email-button',
                '.lock-class.storno-btn',
                '.lock-class.archive-btn'
            ]
        };
    }

    Object.keys(listMainElements).forEach(type => {
        const element = listMainElements[type];
        new OfferInvoiceOrderDeliveryForm(
            element.mainId,
            element.mainFormId,
            element.modalId,
            element.emailHistoryUrl || null,
            element.confirmBtnClass || null,
            element.productPositionUrl || null,
            element.confirmUrl || null,
            element.mainUrl || null,
            element.formInputNames || null,
            element.buttonClasses || null,
            element.dataContainer || null,
            element.confirmDate || null,
            element.designationNumber || null,
            element.cancelform || null,
        );
    });

});


document.addEventListener("DOMContentLoaded", function() {

    const sidebar = document.querySelector('.wrapper-sidebar');
    const pinButton = document.querySelector('.pin-sidebar');
    const wrapperMain = document.querySelector(".wrapper-main");
    const threshold = 20;
    const overlay = document.createElement('div');
    overlay.className = 'sidebar-overlay';
    document.body.appendChild(overlay);

    if (!sidebar || !pinButton || !wrapperMain) {
        return;
    }

    // Status beim Laden prüfen
    const sidebarStatus = localStorage.getItem("sidebarStatus") || "inactive";
    if(sidebarStatus === "active") {
        wrapperMain.classList.add('active');
        sidebar.classList.add('active', 'pinned');   
        overlay.classList.remove('active');  
    }

    pinButton.addEventListener('click', function() {
        if(wrapperMain.classList.contains("active")){
            wrapperMain.classList.remove('active');
            sidebar.classList.remove('active', 'pinned');
            overlay.classList.add('active');
            localStorage.setItem("sidebarStatus", "inactive"); // Status speichern
        } else {
            wrapperMain.classList.add('active');
            sidebar.classList.add('active', 'pinned');   
            overlay.classList.remove('active');
            localStorage.setItem("sidebarStatus", "active"); // Status speichern
        }
    });
    
    document.addEventListener('mousemove', function(e) {
        if (!wrapperMain.classList.contains("active")) {
            if (e.clientX <= threshold) {
                sidebar.classList.add('active');
                overlay.classList.add('active');
            } else if (e.clientX > 250) {
                sidebar.classList.remove('active');
                overlay.classList.remove('active');
            }
        }
    });

});
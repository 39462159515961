
// agriflow/offers/static/js/offers.js

import OffersCreate from "./module/OffersCreate";
import OfferUpdate from "./module/OffersUpdate";
import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import OffersChangeHistory from "./module/OffersChangeHistory.js";
import ArchiveManager from "../../../assets/js/common/ArchiveModal.js";
import SignatureForms from "../../../assets/js/common/Signature.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";

import checkReferences from "../../../assets/js/common/CheckReferences.js";

// ToDo: bestätigen
// import { confirmBtn } from "../../../assets/js/common/Confirm.js";

import EmailHistory from "../../../email_management/static/js/module/EmailHistory.js";

document.addEventListener('DOMContentLoaded', function () {  
    const offercreate = new OffersCreate();
    const offerupdate = new OfferUpdate();
    const offerschangehistory = new OffersChangeHistory();

    // Signatur Save user
    const offerSignatureForm = document.getElementById('offer_signature_form');
    if(offerSignatureForm){
        const signatureave = new SignatureForms();
        signatureave.init('offer_signature_form'); 
    };


    // Pdf Button
    const openPdfOfferButton = document.getElementById('openPdfOfferButton')
    if(!openPdfOfferButton) return
    openPdfOfferButton.addEventListener('click', event => {
        event.preventDefault();
        const pdfUrl = event.currentTarget.getAttribute('data-url');
        var newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } 
    })


    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Anzahl', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'offers',
            'model_name': 'OfferServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'offers',
            'model_name': 'OfferObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'offers',
            'model_name': 'OffersTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': { 'formatted_tax': 1 },
            'app_label': 'offers',
            'model_name': 'OfferMachinerysPositions'
        },
            'products_positions': {
            'fields': ['id', 'products__designation_number', 'products__designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'unit', ''],
            'model_type': 'products',
            'rowClass': 'bg-products-table',
            'colspan': 1,
            'app_label': 'offers',
            'model_name': 'OfferProductPosition'
        }
    };

    const dataContainer = document.getElementById('dataContainerOffer');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOffer', headers, types, 'offers');
    view.init();

    // Hol die History der Emails
    new EmailHistory(urlEmailHistoryOffers);   


    window.reloadPositionsList = async function() {
        view.loadData(); 
    }; 


    // Umwandeln in Aufträge && Rechnungen
    const converttoorders = new ConvertTo();
    const convertcopyToOrderBtn = document.getElementById('copyToOrder'); 
    if(convertcopyToOrderBtn){
        convertcopyToOrderBtn.addEventListener('click', () => {
            const dataConvertUrl = convertcopyToOrderBtn.getAttribute('data-convert-url');
            if (typeof converttoorders !== 'undefined' && converttoorders.init) {
                converttoorders.init(dataConvertUrl);
            }
        });
    }
    

    const convertToInvoiceBtn = document.getElementById('copyToInvoice');
    if (convertToInvoiceBtn) {
        convertToInvoiceBtn.addEventListener('click', () => {
            const dataConvertUrl = convertToInvoiceBtn.getAttribute('data-convert-url');
            if (typeof converttoorders !== 'undefined' && converttoorders.init) {
                converttoorders.init(dataConvertUrl);
            }
        });
    }


    // Address View
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);  
    }, 300);   


    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    if(document.querySelector('[data-permission="delete_offers"]')){
        checkReferences('offers', 'Offers', offersIdVar);
    }

});





function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const initTimetrackingTable = () => {

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    var availableHeight = $(window).height() - $('body').offset().top;
    function setTableHeight() {
        const table = $('#timetrackingViewTable').DataTable({
            processing: true,
            serverSide: true,
            searching: true,
            responsive: true,
            scrollY: availableHeight - 335,
            ajax: {
                url: '/api/timetracking/work-entry-summary/',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                dataSrc: function(json) {
                    return json.data;
                },
            },
            columns: [
                {data: 'employee', title: 'Mitarbeiter'},
                {data: 'start_time', title: 'Startzeit'},
                {data: 'end_time', title: 'Endzeit'},
                {data: 'pause_duration', title: 'Pausenzeit'},
                {data: 'total_worked_time', title: 'Gearbeitete Zeit'}
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
            "createdRow": function(row, data, dataIndex) {
                const url = `/timetracking/timetracking-update-view/${data.id}/`;
                $(row).addClass('clickable-row')
                     .attr('data-url', url)
                     .css('cursor', 'pointer');
            }
        });
    }
    setTableHeight();
    $(window).resize(function() {
        table.columns.adjust().draw();
    });
    $(document).on('click', '.clickable-row', function() {
        window.location.href = $(this).data('url');
    });
}



export const initWorkLogTable = () => {

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    $('#workLogTable').DataTable({
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        ajax: {
            url: '/api/timetracking/1/work-log-summary/',
            headers: {
                'Authorization': `Token ${accessToken}`
            },
            dataSrc: function(json) {
                return json.data;
            },
            error: function(xhr, error, code) {
                console.log("Ajax error: " + error);
            },
        },
        columns: [
            { data: 'user' },
            { data: 'started_at' },
            { data: 'ended_at' },
            { data: 'is_active' }
        ],
        order: [[0, 'desc']],
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });
    
}


import objektCreate from "./module/objektCreate";
import getNextNumerView from '../../../core/static/module/numberRange.js';
import objectChangeHistory from './module/objectChangeHistory';
import objectUpdate  from './module/objectUpdate.js';
import ArchiveManager from '../../../assets/js/common/ArchiveModal.js'
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";
import checkReferences from "../../../assets/js/common/CheckReferences.js";
import loadObjects from "./module/objectGeometry.js";
import { initMap } from "./module/objectGeometry.js";

document.addEventListener('DOMContentLoaded', function () {
    const objektcreate = new objektCreate();
    const objectchangehistory = new objectChangeHistory();
    const objectupdate = new objectUpdate();

    if(document.getElementById('activities-objects-container')){
        const activitieslist = new ActivitiesList(activitiesList, 'activities-objects-container');
        window.reloadActivitiesList = function () {
            if (typeof activitieslist !== 'undefined' && activitieslist) {
                activitieslist.init();
            }
        };
    }
    

    // ToDo Archieruneg dazu machen und Button ID anpssen.
    if(document.querySelector('[data-permission="delete_objects"]')){
        checkReferences('objects', 'Objects', objectIdVar);
    }

    const objectFormsCreateBtn = document.getElementById('objectFormsCreate');
    if(objectFormsCreateBtn) {
        getNextNumerView('OBJ');
    }

    const objectList = document.getElementById("object-list");
    if(objectList){
        loadObjects();
        initMap();
    }

}); 
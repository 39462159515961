
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const movementsTable = (id) => {

    const productId = id;
    const accessToken = getCookie('drfToken');

    const table = $('#movements-table').DataTable({
        processing: true,
        serverSide: true,
        responsive: true,
        ajax: {
            url: `/api/products/detail/${productId}/movement-list/`,
            headers: {
                'Authorization': `Token ${accessToken}`
            },
            data: function(d) {
                d.movement_type = $('#movement-type-filter').val();
            },
            dataSrc: function(response) {
                return response.data;
            },
            error: function(xhr, error, thrown) {
                console.error("API Fehler:", {
                    status: xhr.status,
                    error: error,
                    details: thrown
                });
            }
        },
        columns: [
            { data: 'type', title: 'Art' },
            { data: 'document_number', title: 'Belegnummer' },
            { data: 'date', title: 'Datum' },
            { data: 'quantity', title: 'Menge' },
            { data: 'status', title: 'Status' },
            { data: 'created_by', title: 'Erstellt von' }
        ],
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });

    // Filter für Bewegungsart
    $('#movement-type-filter').on('change', function() {
        table.ajax.reload();
    });

}
    

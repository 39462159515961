

import FetchHandler from "../../../../assets/js/assets/FetchHandler";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const productsStatistics = async (id) => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) return;
    
    const options = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${accessToken}`,
    };

    try {
        const response = await FetchHandler.getData(`/api/products/detail/${id}/products-statistics/`, options);
        const data = await response.json();
        if(data && response.status === 200) {
            console.log(data);
            document.querySelector('.list-group').innerHTML = `
            <li class="list-group-item flex-fill">Menge: ${data.total}</li>
            <li class="list-group-item flex-fill">Liefermenge: ${data.delivered}</li>
            <li class="list-group-item flex-fill">Gelieferte: ${data.remaining}</li>
            <li class="list-group-item flex-fill">Reserviert: ${data.reserved}</li>
            <li class="list-group-item flex-fill">Gepickt: ${data.picked}</li>
            <li class="list-group-item flex-fill">Bewegungen: ${data.total_movements}</li>
        `;
        }
    } catch (error) {
        console.error('Fehler:', error);
    }
}
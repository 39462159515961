// agriflow/orders/static/js/orders.js

import CreateOrders from "./module/CreateOrders";
import UpdateOrders from "./module/UpdateOrders";
import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import ordersChangeHistory from "./module/ordersChangeHistory.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";
import SignatureForms from "../../../assets/js/common/Signature.js";

import checkReferences from "../../../assets/js/common/CheckReferences.js";

// import { confirmBtn } from "../../../assets/js/common/Confirm.js";
// import ArchiveManager from "../../../assets/js/common/ArchiveModal.js";

import EmailHistory from "../../../email_management/static/js/module/EmailHistory.js";


document.addEventListener('DOMContentLoaded', function () {
    const oderscreate = new CreateOrders();
    const updateorders = new UpdateOrders();
    const orderschangehistory = new ordersChangeHistory();


    // Signatur
    const ordersSignatureForm = document.getElementById('orders_signature_form');
    if(ordersSignatureForm){
        const signatureave = new SignatureForms(ordersSignatureForm);
        signatureave.init('orders_signature_form'); 
    };


    // Pdf Button
    const openPdfOrdersButton = document.getElementById('openPdfOrdersButton')
    if(!openPdfOrdersButton) return
    openPdfOrdersButton.addEventListener('click', event => {
        event.preventDefault();
        const pdfUrl = event.currentTarget.getAttribute('data-url');
        var newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } 
    });

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Bestellte Menge', 'Gelieferte Menge', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'orders',
            'model_name': 'OrderServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 8 },
            'app_label': 'orders',
            'model_name': 'OrderObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 9 },
            'app_label': 'orders',
            'model_name': 'OrderTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': 1,
            'app_label': 'orders',
            'model_name': 'OrderMachinerysPosition'
        },
            'products_positions': {
            'fields': ['id', 'products__designation_number', 'products__designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'products',
            'rowClass': 'bg-products-table',
            'colspan': 1,
            'app_label': 'orders',
            'model_name': 'OrderProductPosition'
        } 
    };

    const dataContainer = document.querySelector('#dataContainerOrder');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOrder', headers, types, 'orders');
    view.init();
    
    new EmailHistory(urlEmailHistoryOrders);
    window.reloadPositionsList = function() {
        view.loadData();
    };

    // Umwandlen von Auftrag in Rechnungen 
    const converordertoinvoice = new ConvertTo();
    const OrderToInvoiceBtn = document.getElementById('copyOrderToInvoice');
    if(OrderToInvoiceBtn){
        OrderToInvoiceBtn.addEventListener('click', () => {
            console.log(OrderToInvoiceBtn)
            const dataConvertUrl = OrderToInvoiceBtn.getAttribute('data-convert-url')
            if (typeof converordertoinvoice !== 'undefined' && converordertoinvoice.init) {
                converordertoinvoice.init(dataConvertUrl);
            }
        });
    }
    

    // Address ansicht
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);    
    }, 300);   


    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    if(document.querySelector('[data-permission="update_orders"]')){
        checkReferences('orders', 'Orders', ordersIdVar);
    }
    

    // Archivieren Orders // ToDo Vielleicht löschen
    /* const archiveBtn = document.getElementById('archiveBtn');
    if(!archiveBtn) return;
    const archiveManager = new ArchiveManager(archiveBtn);
    archiveBtn.addEventListener('click', () => {
        const url = archiveBtn.getAttribute('data-archive'); 
        archiveManager.toggleArchive(url)
    });
 */
    // Müll kann vielleicht weg:


    // Activities ListView
    // const activitieslist = new ActivitiesList(activitiesList, 'activities-orders-container');
    // window.reloadActivitiesList = function () {
    //     if (typeof activitieslist !== 'undefined' && activitieslist) {
    //         activitieslist.init();
    //     }
    // };

    // ToDo noch mal den Bestätigen nur über den Button!
    // const btn = document.querySelector('.confirm-btn');

});

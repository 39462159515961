
import CreateDeliveryNote from "./module/CreateDeliveryNote";
import UpdateDeliveryNote from "./module/UpdateDeliveryNote";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import DeliveryNoteChangeHistory from "./module/DeliveryNoteChangeHistory.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";
import checkReferences from "../../../assets/js/common/CheckReferences.js";

// import { confirmBtn } from "../../../assets/js/common/Confirm.js";
// import ArchiveManager from "../../../assets/js/common/ArchiveModal.js";

import SignatureForms from "../../../assets/js/common/Signature.js";
import EmailHistory from "../../../email_management/static/js/module/EmailHistory.js";
import ConvertTo from "../../../assets/js/common/ConvertTo.js";

document.addEventListener('DOMContentLoaded', function () {
    const createdeliverynote = new CreateDeliveryNote();
    const updatedeliverynote = new UpdateDeliveryNote();
    const deliverynotechangehistory = new DeliveryNoteChangeHistory();

    // Signatur Save user
    const deliveryNoteSignatureForm = document.getElementById('delivery_note_signature_form');
    if(deliveryNoteSignatureForm){
        const signatureave = new SignatureForms();
        signatureave.init('delivery_note_signature_form'); 
    };

    // Pdf Button 
    const openPdfDeliveryNoteButton = document.getElementById('openPdfDeliveryNoteButton')
    if(!openPdfDeliveryNoteButton) return
    openPdfDeliveryNoteButton.addEventListener('click', event => {
        event.preventDefault();
        const pdfUrl = event.currentTarget.getAttribute('data-url');
        var newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } 
    })

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Bestellte Menge', 'Gelieferte Menge', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 8 },
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteObjectPosition' 
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 9 },
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspan': 1,
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteMachineryPosition'
        },
            'products_positions': {
            'fields': ['id', 'products__designation_number', 'products__designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'products',
            'rowClass': 'bg-products-table',
            'colspan': 1,
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteProducts'
        }
    };

    const dataContainer = document.querySelector('#dataContainerDeliveryNote');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerDeliveryNote', headers, types, 'delivery');
    view.init(); 

    window.reloadPositionsList = function() {
        view.loadData();
    };

    new EmailHistory(urlEmailHistoryDeliveryNote); 
        
    // Umwandlen in
    const converdeliveredinvoice = new ConvertTo();
    const button = document.getElementById('copyDeliveryNoteToInvoice');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converdeliveredinvoice.init(dataConvertUrl);
    });

    // Kunden Adresse
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);    
    }, 300);  

    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    // Aktivitäten
    const activitieslist = new ActivitiesList(activitiesList, 'activities-delivery_note-container');
    window.reloadActivitiesList = function () {
        if (typeof activitieslist !== 'undefined' && activitieslist) {
            activitieslist.init();
        }
    };


    if(document.querySelector('[data-permission="delete_delivery_note"]')){
        checkReferences('delivery_note', 'deliverynote', deliveryNoteIdVar);
    }

});

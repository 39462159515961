

import { ChangeHistory } from "../../../../assets/js/modules/changeHistory";

export default class ProductNoteChangeHistory {
    constructor(){
        this.changeHistoryContainer = document.querySelector('#change-history-container-products');
        if(!this.changeHistoryContainer) return;
        this.event();  
    }
    event(){
        if (this.changeHistoryContainer) {
            const modelName = 'products';
            const objectId = productsId;
            const fieldsToShow = [
                'designation', 
                'designation_number', 
                'product_type', 
                'delivery_time',
                'unit',
                'cost_per_unit',
                'warning_limit',
                'tax',
                'discount'];
            const fieldLabels = {
                designation_number: 'Bezeichnungsnummer',
                designation_number: 'Produktnummer',
                product_type: 'Produkttyp',
                delivery_time: 'Auslieferungszeit (Tage)',
                unit: 'Einheit',
                cost_per_unit: 'Kosten pro Einheit',
                warning_limit: 'warning_limit',
                tax: 'Steuer',
                discount: 'Rabatt'
            };
            const changeHistory = new ChangeHistory(modelName, objectId);
            changeHistory.renderChanges('change-history-container-products', fieldsToShow, fieldLabels);
        }
    }
}
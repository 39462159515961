
import ModalsGeneric from "../../../../assets/js/common/ModalGeneric";

export default class StornInvoices {
    constructor(btnId) {
        this.btnId = btnId;
        this.modalService = new ModalsGeneric();
        this.addCloseEventListeners();
    }

    initializeModal() { 
        const modalElement = document.querySelector('#activitiesModal');
        if (!modalElement) {
            console.error('Modal-Element #activitiesModal nicht gefunden');
            return;
        }
        this.modalService.init('#activitiesModal');
    }

    addCloseEventListeners() {
        document.querySelectorAll('.close-modal-button-activities').forEach(button => {
            button.addEventListener('click', () => {
                this.modalService.closeModal();
            });
        });
    }

    closeModal(){
        if (this.modalService) {
            this.modalInstance.hide();
        }
    }
    
    async init() {
        if (!this.btnId) {
            console.log('Storno-Button wurde nicht gefunden'); 
            return;
        }
        
        this.btnId.addEventListener('click', async () => {            
            const url = this.btnId.getAttribute('data-storno');
            if (!url) {
                console.log('Keine URL im Button-Attribut gefunden');
                return;
            }
            this.initializeModal();
            this.modalService.loadModalData(url);
        }); 
    }


}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const transactionsTable = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }
    $('#inventory-input-table').DataTable({
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        ajax: {
            url: '/api/warehouse/detail/warenhouse-inventory-inputs/',
            headers: {
                'Authorization': `Token ${accessToken}`
            },
            dataSrc: function(json) {
                //console.log(json);
                return json.data;
            },
        },
        columns: [
            {
                "data": "input_number",
                "title": "Eingangsnummer"
            },
            {
                "data": "received_date",
                "title": "Eingangsdatum"
            },
            {
                "data": "status",
                "title": "Status"
            },
            {
                "data": "product_order",
                "title": "Auftrag"
            },
            {
                "data": "received_by",
                "title": "Angenommen von"
            },
            {
                "data": "stock_movement.movement_type",
                "render": function(data, type, row) {
                    return row.stock_movement ? row.stock_movement.movement_type : '-';
                }
            }
        ],
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });
}
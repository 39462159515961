

import ActivitiesList from "../../../activities/static/js/module/ActivitiesList";
import CreateStorage from "./module/StorageCreate";
import StorageUpdate from "./module/StorageUpdate";
import InventoryCount from "./module/InventoryCountCreate";
import FetchHandler from "../../../assets/js/assets/FetchHandler";
import { currentstockTable } from "./currentstock/currentstockTable";
import { transactionsTable } from "./transactions/transactionsTable";
import { inventorySelectProduct } from "./module/inventorySelectProduct";

import { initInventoryCount } from "./module/inventoryCountList";
import { inventoryOutputs } from "./outputs/inventory-outputs";

import { InventoryTransfersList } from "./transfers/InventoryTransfersList";
import StorageNodeChangeHistory from "./module/StorageNodeChangeHistory";

import InventoryTransfersCreate from "./transfers/InventoryTransfersCreate";

document.addEventListener('DOMContentLoaded', function() {
    inventorySelectProduct();
    new CreateStorage();
    new StorageUpdate();
    new InventoryCount();
    new StorageNodeChangeHistory();
    new InventoryTransfersCreate();
    
    const inventoryTable = document.getElementById('inventory-table');
    if(inventoryTable){
        currentstockTable();
    }
    
    const inventoryOutputsData = document.getElementById('inventory-outputs');
    if(inventoryOutputsData){
        inventoryOutputs()
    }

    const inventoryInputTable = document.getElementById('inventory-input-table');
    if(inventoryInputTable){
        transactionsTable();
    }

    const inventoryTransfersTable = document.getElementById('inventory-transfers-table')
    if(inventoryTransfersTable){
        InventoryTransfersList();
    }

    async function getWarenhouse(){
        const response = await FetchHandler.getData(warenhouseDeatile);
        const data = await response.json();
        console.log("Daten:", data);
        if (data.qr_code) {
            const qrImg = document.querySelector('.placeholder');
            qrImg.src = data.qr_code;
            
            const downloadLink = document.createElement('a');
            downloadLink.href = data.qr_code;
            downloadLink.download = '';
            downloadLink.style.cursor = 'pointer';
            
            qrImg.parentNode.replaceChild(downloadLink, qrImg);
            downloadLink.appendChild(qrImg);
        }
    }
    if(typeof warenhouseDeatile !== 'undefined'){
        getWarenhouse();
    }
    if(typeof inventoryCountId !== 'undefined'){
        initInventoryCount(inventoryCountId); 
    }

    // Aktivitäten
    const activitiesStorageNodeContainer = document.getElementById('activities-storage-node-container');
    if(activitiesStorageNodeContainer){
        const activitieslist = new ActivitiesList(activitiesList, 'activities-storage-node-container');
        window.reloadActivitiesList = function () {
            if (typeof activitieslist !== 'undefined' && activitieslist) {
                activitieslist.init();
            }
        };
    }


    
});

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const currentstockTable = () => {


    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    $('#inventory-table').DataTable({
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        ajax: {
            url: '/api/warehouse/detail/warenhouse-currentstock/',
            headers: {
                'Authorization': `Token ${accessToken}`
            },
            dataSrc: function(json) {
                // console.log(json);
                return json.data;
            },
        },
        columns: [
            {
                "data": "product.designation",
                "title": "Produkt"
            },
            {
                "data": "storage_location.name",
                "title": "Lagerplatz"
            },
            {
                "data": "available_quantity",
                "title": "Bestandsmenge",
                "render": function(data, type, row) {
                    return data + ' ' + row.product.unit;
                }
            },
            {
                "data": "reserved_quantity",
                "title": "Vorgemerkte Menge",
                "render": function(data, type, row) {
                    return data + ' ' + row.product.unit;
                }
            },
            {
                "data": "batch_number.batch_number",
                "title": "Chargennummer",
                "render": function(data, type, row) {
                    return row.batch_number ? row.batch_number.batch_number : '-';
                }
            },
            {
                "data": "created_at",
                "title": "Erstellt am",
            },
            {
                "data": "updated_at",
                "title": "Letzte Änderung",
            }
        ],
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });

}


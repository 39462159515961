
/**
 * Klasse für das Handling von Angeboten, Rechnungen, Aufträgen und Lieferscheinen
 * - Formular sperren
 * - Modal anzeigen
 * - Status "Bestätigt" entfernen
 * - Bestätigung durchführen
 */


// Wen ich eines der Hauptfunktien ladn dan lade 
// ich auch immer das alles mit dan kanich das auch gleich in eine Klasse verwaten.
// Gut wären wen ich auch gleich das Erstllen mit machen kann machen kann !

import { Modal } from 'bootstrap';
import FetchHandler from "../assets/FetchHandler"; 
import AlertHandler from "./alertHandler";
import ModalsGeneric from "../common/ModalGeneric";
import { event } from "jquery";


export default class OfferInvoiceOrderDeliveryForm {
    constructor(
        mainId, 
        mainFormId, 
        mainModalId=null, 
        emailHistoryUrl=null, 
        confirmBtnClass=null, 
        productPositionUrl=null, 
        confirmUrl=null,
        mainUrl=null,
        formInputNames=null,
        buttonClasses=null,
        dataContainer=null,
        confirmDate=null,
        designationNumber=null,
        cancelform=null){

        this.mainId = mainId;
        this.mainFormId = mainFormId;
        this.mainModalId = mainModalId;
        this.emailHistoryUrl = emailHistoryUrl;
        this.confirmBtnClass = document.querySelector(confirmBtnClass);
        this.productPositionUrl = productPositionUrl;
        this.confirmUrl = confirmUrl;
        this.mainUrl = mainUrl;
        this.formInputNames = formInputNames;
        this.buttonClasses = buttonClasses;
        this.dataContainer = dataContainer;
        this.confirmDate = confirmDate;
        this.designationNumber = designationNumber;
        this.modelCancelForm = cancelform;

        this.modal = new ModalsGeneric();
        this.init();
        this.isUpdating = false; 
        this.positionsData;
    }

    init() {
        const form = document.querySelector(this.mainFormId);
        if (!form) return;

        document.querySelector('.lock-class.archive-btn')?.addEventListener('click', 
            (event) => this.archiveModal(event)
        );
        document.addEventListener('dataSaved', () => {this.checkData(this.mainUrl);}); // Event auslösen damit die Daten in der Main neu geladen werden
        this.checkData(this.mainUrl); // Prüft die bestden daten.
        this.confirmBtnClass.addEventListener('click', () => this.confirmBtn(this)); // Bestätigen Button
        form.addEventListener('change', (event) => this.handleChange(event)); // Normale Inputs
        $(this.mainFormId).on('select2:select select2:unselect', (event) => this.handleChange(event)); // Select2
        $('.datepicker').on('changeDate', (event) => this.handleChange(event)); // Datepicker 
        document.querySelector('.cancel-btn')?.addEventListener('click', (event) => this.getCancel(event));

        console.log(document.querySelector('.cancel-btn')) 
    }


    handleChange(event) {
        if (this.isUpdating) return;
        this.isUpdating = true;
        this.updateForm(event);
        setTimeout(() => {
            this.isUpdating = false;
        }, 1000);
    }


    async updateForm(event) {
        const formElement = event.target.closest('form');
        console.log(formElement)
        if (!formElement) return;
        try {
            const { status, body } = await FetchHandler.sendFormData(formElement, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' }
            });
            if (status === 200 && body.success) {
                this.updateFields(formElement, body.updated_data);
                this.checkData(this.mainUrl);
                AlertHandler.showAlert(body.message, 'success');
            } else {
                console.error('Fehlerhafte Antwort:', body);
                AlertHandler.showAlert(body.errors || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error) {
            console.error(error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        }
    }

    updateFields(formElement, updatedData) {

        const oldValues = {
            offer_number: formElement.querySelector('[name="offer_number"]')?.value,
            order_number: formElement.querySelector('[name="order_number"]')?.value,
            deliverynote_number: formElement.querySelector('[name="deliverynote_number"]')?.value
        };

        Object.entries(updatedData).forEach(([field, value]) => {
            const fieldElement = formElement.querySelector(`[name="${field}"]`);
            if (fieldElement) {
                
                if (field in oldValues) {
                    fieldElement.value = oldValues[field];
                    return;
                }

                if (fieldElement.value !== value) {
                    if ($(fieldElement).data('select2')) {
                        $(fieldElement).val(value).trigger('change.select2');
                    } else if (fieldElement.classList?.contains('datepicker')) {
                        $(fieldElement).datepicker('setDate', value);
                    } else if (['checkbox', 'radio'].includes(fieldElement.type)) {
                        fieldElement.checked = Boolean(value);
                    } else {
                        fieldElement.value = value;
                    }
                }
            }
        });
    }


    initializeModal(modal) {
        const modalElement = document.querySelector(modal);
        if (!modalElement) console.error('Modal-Element #activitiesModal nicht gefunden');   
        this.modal.init(modal);
        this.modal.modalInstance.show();
    }

    async confirmBtn(self) {
        const accessToken = this.getCookie('drfToken');
        if (!accessToken) return;
        this.initializeModal(this.mainModalId);
        const url = this.confirmBtnClass.dataset.url;
        const modalBody = document.querySelector(`${this.mainModalId} .modal-body`); 
        modalBody.innerHTML = ''
        try {
            const options = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`,
            };
            const response = await FetchHandler.getData(url, options);
            const data = await response.json();
            if(data.success){

                this.positionsData = data.inventory_data; // Positionsdaten
                if (data.inventory_data && data.inventory_data.length > 0) {
                    const htmlInventory = this.renderInventoryData(data.inventory_data);
                    document.querySelector(`${this.mainModalId} .modal-body`).innerHTML += htmlInventory;
                }
                
                const html = this.renderBaseData(data.base_data);
                document.querySelector(`${this.mainModalId} .modal-body`).innerHTML += html;
                
                // Bestätiungsbutton
                const modalFooter = document.querySelector(`${this.mainModalId} .modal-footer`);
                if (!modalFooter.querySelector('.confirm-delivery-btn')) {
                    const confirmButton = document.createElement('button');
                    confirmButton.type = 'button';
                    confirmButton.className = 'btn btn-success btn-sm confirm-delivery-btn';
                    confirmButton.innerHTML = 'Bestätigen';
                    confirmButton.dataset.url = this.confirmUrl;
                    
                    const closeButton = modalFooter.querySelector('.close-modal-button-inventory');
                    modalFooter.insertBefore(confirmButton, closeButton);
                }
                $('.select2-inventory').select2({
                    dropdownParent: `${this.mainModalId} .modal-body`,
                    placeholder: "Lagerbestand wählen...",
                    allowClear: true,
                });
                $('.save-inventory-btn').on('click', () => this.saveInventorySelections());
                $('.confirm-delivery-btn').on('click', (event) => this.confirmBtnModal(event));
            }
        } catch (error) {
            console.error('Fehler:', error);
        }
        document.querySelectorAll('.close-modal-button-inventory').forEach(button => {
            button.addEventListener('click', () => {
                this.modal.closeModal();
            });
        });
    }

    renderInventoryData(data) {
        const html = `
            <table class="table table-sm mt-3">
                <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Benötigt</th>
                        <th>Lagerbestand wählen</th>
                        <th>Menge</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(item => `
                        <tr>
                            <td data-id="${item.id}">${item.product.name}</td>
                            <td>${item.required}</td>
                            <td>
                                <select class="form-select form-select-sm select2-inventory" 
                                        data-product-id="${item.product.id}"
                                        style="width: 100%;">
                                    <option value="">Bitte wählen...</option>
                                    ${item.inventory_list.map(inv => `
                                        <option value="${inv.id}" 
                                                data-available="${inv.available}">
                                            Lager: ${inv.location} | Charge: ${inv.batch || '-'} | Verfügbar: ${inv.available} verfügbar
                                        </option>
                                    `).join('')}
                                </select>
                            </td>
                            <td>
                                <input type="number" 
                                       class="form-control form-control-sm inventory-amount" 
                                       style="width: 80px;"
                                       min="0" 
                                       max="${item.required}"
                                       value="${item.required}">
                            </td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>

            <div class="mt-3 text-end">
                <button type="button" class="btn btn-primary btn-sm save-inventory-btn">Speichern</button>
            </div>
        `;
        return html;
    }
    renderBaseData(data) {
        const html = `
            <div class="base-data-container">
                <div class="row mb-2">
                    <div class="col-6">
                        <strong>Nummer:</strong> ${data.designation_number || '-'}
                    </div>
                    <div class="col-6">
                        <strong>Datum:</strong> ${data.date_created || '-'}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6">
                        <strong>Kunde:</strong> ${data.customer || '-'}
                    </div>
                    <div class="col-6">
                        <strong>Status:</strong> ${data.status || '-'}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <strong>Netto:</strong> ${data.amount || '0'} €
                    </div>
                    <div class="col-4">
                        <strong>Rabatt:</strong> ${data.discount || '0'} €
                    </div>
                    <div class="col-4">
                        <strong>Gesamt:</strong> ${data.total_amount || '0'} €
                    </div>
                </div>
            </div>
        `;
        return html;
    }

    async saveInventorySelections(){
        const self = this; 
        try {
            const formData = new FormData();
            const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
            let requestUrl = null; 
            for (const select of $('.select2-inventory')) {
                const $select = $(select);
                const $row = $select.closest('tr');
                const positionId = $row.find('td').first().data('id'); 
                const inventoryId = $select.val();
                const amount = $row.find('.inventory-amount').val();

                if (!self.productPositionUrl)return;

                requestUrl = self.productPositionUrl.replace('0', positionId);        
                if (inventoryId) {
                    const position = self.positionsData.find(p => p.id === positionId);
                    formData.append('pieces', position.pieces);
                    formData.append('inventory', inventoryId);
                    formData.append('cost_per_unit', position.cost_per_unit);
                    formData.append('delivered_quantity', amount);
                    formData.append('object_id', position.object_id);
                    formData.append('products', position.products);
                    formData.append('tax', position.tax);
                    formData.append('unit', position.unit);
                }
                // Speichern
                const response = await fetch(requestUrl, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRFToken': csrfToken 
                    },
                    body: formData,
                });
                if (!response.ok) {
                    throw new Error(`Fehler beim Speichern: ${response.status}`);
                }
                const responseData = await response.json();
                if(responseData.success){
                    window.reloadPositionsList();
                    AlertHandler.showAlert(data.message, 'success');
                }
            }
        } catch(error) {
            console.error('Fehler:', error);
        }
    }

    confirmBtnModal(event) {
        try {
            const inventorySelects = document.querySelectorAll('.select2-inventory');
            const inventoryAmounts = document.querySelectorAll('.inventory-amount');
            let hasError = false;
            inventorySelects.forEach((select, index) => {
                if (!select.value) {
                    // console.error('Kein Lagerbestand ausgewählt in Zeile', index + 1);
                    hasError = true;
                }
                
                const amount = inventoryAmounts[index].value;
                if (!amount || amount <= 0) {
                    // console.error('Keine gültige Menge in Zeile', index + 1);
                    hasError = true;
                }
            });
            if (hasError) {
                alert('Bitte alle Lagerbestände und Mengen auswählen!');
                return;
            }
            const  url = event.target.getAttribute('data-url')
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            fetch(url, {
                method: 'POST',
                headers: {
                    'X-CSRFToken': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.modal.closeModal();
                    this.checkData(this.mainUrl)
                    AlertHandler.showAlert(data.message, 'success');
                } else{
                    AlertHandler.showAlert(data.message, 'danger');
                }
            })
            .catch(error => {
                AlertHandler.showAlert("Ein unbekannter Fehler ist aufgetreten.", 'danger'); 
            });   
        } catch(error) {
            console.error('Fehler:', error);
        }
    }

    // Prüft die daten 
    async checkData(url) {
        try {
            const response = await FetchHandler.getData(url, { 
                'headers': {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
            const data = await response.json();
            const results = data.instance_data
            console.log(results)
            // Läst buttons verschwiden 
            if (data.combined_positions.length === 0) {
                // Buttons verstecken
                this.buttonStatesHidden()
                
            } else {
                // Buttons anzeigen
                this.buttonStatesVisible()
            }
            
            const signatureContainer = document.getElementById('signaturContainer');

            if (results?.storno_text) {
                const stornoContent = `
                    <div class="storno-box">
                        <p class="mb-0 pb-0">Storno-Information:</p>
                        <p>${results.storno_text}</p>
                    </div>
                `;
                signatureContainer.innerHTML += stornoContent;
            }

            if (results?.signature) {
                const signatureContent = `
                    <div class="signature-box">
                        <img src="${results.signature}" style="max-width: 100%; height: auto;">
                        <div class="signature-info">
                            <p>Unterschrift von: ${results.signature_name || '-'}</p>
                            <p>Datum: ${results.date_created || '-'}</p>
                            <p>✓ Geschäftsbedingungen akzeptiert</p>
                            <p>✓ Einverständnis zur digitalen Unterschrift</p>
                        </div>
                    </div>
                `;
                signatureContainer.innerHTML += signatureContent;
            }
            
            // Basis daten
            if (this.designationNumber && this.designationNumber !== '') {
                document.getElementById(this.designationNumber).textContent = results.designation_number;
            }
            if (this.confirmDate && this.confirmDate !== '') {
                const formattedDate = results.date_confirmed === "None" || !results.date_confirmed
                    ? '-' : new Date(results.date_confirmed).toLocaleString('de-DE');
                document.getElementById(this.confirmDate).textContent = formattedDate;
            }


            // Stauts prüfen 
            console.log(results.status)
            if (results.status == 'Abgebrochen') {
                // Code für Abgebrochen
                const stronoBtn = document.querySelector('.cancel-btn');
                if (stronoBtn) stronoBtn.style.display = 'none';
                if (this.formInputNames && Object.keys(this.formInputNames).length > 0) {
                    this.lockForm(this.formInputNames);
                }  

                const form = document.querySelector(this.mainFormId);
                const statusSelect = form.querySelector('[name="status"]');  
                statusSelect.innerHTML = '';
                const allOptions = [
                    'Abgebrochen',
                ];
                allOptions.forEach(optionValue => {
                    const option = document.createElement('option');
                    option.value = optionValue;
                    option.textContent = optionValue;
                    if (optionValue === 'Bestätigt') {
                        option.selected = true;
                    }
                    statusSelect.appendChild(option);
                });
            } else if (results.status == 'Bestätigt') {

                // Formular felder speeren nach Namen das was übergeben wird.
                if (this.formInputNames && Object.keys(this.formInputNames).length > 0) {
                    this.lockForm(this.formInputNames);
                }  
                const form = document.querySelector(this.mainFormId);
                const statusSelect = form.querySelector('[name="status"]');  
                statusSelect.innerHTML = '';
                const allOptions = [
                    'Bestätigt',
                ];
                allOptions.forEach(optionValue => {
                    const option = document.createElement('option');
                    option.value = optionValue;
                    option.textContent = optionValue;
                    if (optionValue === 'Bestätigt') {
                        option.selected = true;
                    }
                    statusSelect.appendChild(option);
                });
            } else if (results.status == 'Offen') {
                // Entfert den Status
                this.statusSelectCheck();
                const stronoBtn = document.getElementById('stornoBtn');
                if (stronoBtn) stronoBtn.style.display = 'none';
            } else if (results.status == 'Entwurf') {
                // Entfert den Status
                this.statusSelectCheck();
                const stronoBtn = document.getElementById('stornoBtn');
                if (stronoBtn) stronoBtn.style.display = 'none';
                // Buttons verstecken
                this.buttonStatesHidden()
            } else if (results.status == 'Storno') {
                const form = document.querySelector(this.mainFormId);
                const statusSelect = form.querySelector('[name="status"]');
                const allOptions = [
                    'Abgebrochen',
                    'Versendet',
                    'Offen',
                    'Bestätigt',
                    'Entwurf',
                    'Storno'
                ];
                statusSelect.innerHTML = '';
                allOptions.forEach(optionValue => {
                    const option = document.createElement('option');
                    option.value = optionValue;
                    option.textContent = optionValue;
                    if (optionValue === 'Storno') {
                        option.selected = true;
                    }
                    statusSelect.appendChild(option);
                });
                Array.from(statusSelect.options).forEach(option => {
                    if (option.value !== 'Storno') {
                        option.remove();
                    }
                });
                this.buttonStatesHidden();
                if (this.formInputNames && Object.keys(this.formInputNames).length > 0) {
                    this.lockForm(this.formInputNames);
                }
            } else {
                //console.log(results.status);
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Buttons anzeigen
    buttonStatesVisible() {
        this.buttonClasses.forEach(className => {
            const btn = document.querySelector(className);
            if (btn) btn.style.display = 'block';
        })
    }

    // Buttons verstecken
    buttonStatesHidden() {
        this.buttonClasses.forEach(className => {
            const btn = document.querySelector(className);
            if (btn) btn.style.display = 'none';
        });
    }
    
    // Entfert den Status
    statusSelectCheck() {
        const form = document.querySelector(this.mainFormId);
        const statusSelect = form.querySelector('[name="status"]');
        if (statusSelect) {
            Array.from(statusSelect.options).forEach(option => {
                if (option.value === 'Bestätigt' || 
                    option.value === 'Abgebrochen' || 
                    option.value === 'Storno' || 
                    option.value === 'Gezeichnet' || 
                    option.value === 'Versendet') {
                    option.remove();
                }
            });
        }
    }

    // Formular speeren
    lockForm(data) {
        data?.dates?.forEach(date => {
            const field = $(`[name="${date}"]`);
            field.length && field.prop('disabled', true);
        });
        data?.select2?.forEach(select => {
            const field = $(`[name="${select}"]`);
            field.length && field.prop('disabled', true).trigger('change');
        });
        data?.inputs?.forEach(input => {
            const field = $(`[name="${input}"]`);
            field.length && field.prop('disabled', true);
        });
        data?.btnClass?.forEach(className => {
            document.querySelectorAll(`.${className}`)?.forEach(button => 
                button.disabled = true
            );
        });
        const container = document.getElementById(this.dataContainer);        
        const observer = new MutationObserver(() => {
            data.btnClass.forEach(className => {
                const elements = container.querySelectorAll(`.${className}`);
                elements.forEach(el => {
                    el.style.pointerEvents = 'none';
                    el.style.opacity = '0.5';
                });
            });
        });
        observer.observe(container, { childList: true, subtree: true });
    }

    // Formular entspeeren 
    unlockForm(data) {
        data?.dates?.forEach(date => {
            const field = $(`[name="${date}"]`);
            field.length && field.prop('disabled', false);
        });
        data?.select2?.forEach(select => {
            const field = $(`[name="${select}"]`);
            field.length && field.prop('disabled', false).trigger('change');
        });
        data?.inputs?.forEach(input => {
            const field = $(`[name="${input}"]`);
            field.length && field.prop('disabled', false);
        });

        data?.btnClass?.forEach(className => {
            document.querySelectorAll(`.${className}`)?.forEach(button => 
                button.disabled = false
            );
        });
        const container = document.getElementById(this.dataContainer);
        if (container) {
            data?.btnClass?.forEach(className => {
                const elements = container.querySelectorAll(`.${className}`);
                elements.forEach(el => {
                    el.style.pointerEvents = 'auto';
                    el.style.opacity = '1';
                });
            });
        }
        observer.observe(container, { childList: true, subtree: true });
    }

    // Darf nicht gelöscht werden den es mit rigen was verbunden ist.
    // agriflow/assets/js/common/CheckReferences.js
    checkReferences() {}

    // Check die rechte der Benutzer
    // agriflow/access_controls/static/js/modles/checkAccess.js

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    // ArchiveModal so das ich den user eine hinwis geb kann.
    async archiveModal(event) {
        const url = event.target.getAttribute('data-archive');
        if(!url)return;
        const modalElement = '#genericModal';
        this.initializeModal(modalElement);
        try {
            const response = await FetchHandler.getData(url, {
                'headers': {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
            const data = await response.json();
            if (data.html) {
                const modal = document.querySelector(`${modalElement} .modal-content`)               
                modal.innerHTML = data.html;
                const archiveBtn = modal.querySelector('.btn.archive');
                if (archiveBtn) {
                    const archiveUrl = archiveBtn.getAttribute('data-url');
                    archiveBtn.addEventListener('click', (event) => {
                        this.archiveSave(archiveUrl, modalElement)
                    });
                }
            } else {
                console.error('No HTML content in response');
            }
        } catch (error) {
            console.error('Failed to html content:', error);
        }    
    }

    async archiveSave(url, modalElement) {
        const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value; 
        fetch(url, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                if (data.is_archived) {
                    const archiveBtn = document.querySelector('.lock-class.archive-btn');
                    if (archiveBtn) {
                        archiveBtn.textContent = 'Dearchivieren';
                        
                        const allDeleteButtons = document.querySelectorAll('.lock-class.btn-danger');
                        allDeleteButtons.forEach(btn => btn.remove());

                        const deleteLink = document.createElement('a');
                        deleteLink.href = data.delete_url;
                        deleteLink.textContent = 'Löschen';
                        deleteLink.className = 'btn btn-danger btn-sm';
                        deleteLink.setAttribute('data-permission', 'delete_invoices');
                        archiveBtn.insertAdjacentElement('afterend', deleteLink);
                    }
                } else {
                    const archiveBtn = document.querySelector('.lock-class.archive-btn');
                    if (archiveBtn) {
                        archiveBtn.style.display = '';
                        archiveBtn.textContent = 'Archivieren';
                        const deleteLink = archiveBtn.nextElementSibling;
                        if (deleteLink && deleteLink.textContent === 'Löschen') {
                            deleteLink.remove();
                        }
                    }
                }
                const modal = Modal.getInstance(modalElement);
                modal.hide();
            } else {
                console.error('Fehler:', data.error);
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
        })
    }

    
    // Abbrechen Auftrag Angebot Lieferschen 
    async getCancel(event){
        event.preventDefault(); 

        const cancelUrl = event.target.dataset.cancel;
        console.log('Cancel URL:', cancelUrl);

        if (!cancelUrl) {
            return;
        }
        const modalElement = '#myModalCancel';
        this.initializeModal(modalElement);
        try {
            const response = await FetchHandler.getData(cancelUrl, {
                'headers': {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
            const data = await response.json();
            if (data.html) {
                const modal = document.querySelector(`${modalElement} .modal-body`);
                if (modal) {
                    modal.innerHTML = data.html; 
                    document.querySelector(modalElement).removeAttribute('aria-hidden');
                    this.addCloseEventListeners();

                    document.querySelector(this.modelCancelForm)?.addEventListener('submit', 
                        (event) => {
                            event.preventDefault();
                            this.postCancel();
                        }
                    );
                }
            }
        } catch (error) {
            console.error('Failed to html content:', error);
        }  
    }

    addCloseEventListeners() {
        document.querySelectorAll('.close-modal-cancel').forEach(button => {
            button.addEventListener('click', () => {
                this.modal.closeModal();
            });
        });
    }

    async postCancel() {
        const form = document.querySelector(this.modelCancelForm);
        if (!form) return;

        const formData = new FormData(form);
        const url = form.getAttribute('action');
        const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'same-origin', 
                body: formData
            });

            const data = await response.json();
            if (data.success) {
                this.modal.closeModal();
                this.checkData(this.mainUrl);
                AlertHandler.showAlert(data.message, 'success');
            } else {
                AlertHandler.showAlert(data.message || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Stornieren:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten', 'danger');
        }
    }


   
    
    // TODO: Weitere Funktionen wie Stornierung etc.

    // Aktivitäten History
    
    // Email History
    // agriflow/offers/templates/offers/offers_update.html
    // agriflow/offers/static/js/offers.js

    // Löschen wen feritig "agriflow/assets/js/common/Confirm.js"
}

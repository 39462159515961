
/********************************************/
/* 1) FUNKTION ZUM LADEN ALLER OBJEKTE      */
/********************************************/

function hasValidGeometry(geometry) {
    if (!geometry) return false;
    if (Array.isArray(geometry)) {
        return geometry.length > 0;
    } else if (typeof geometry === 'object') {
        return Object.keys(geometry).length > 0;
    }
    return false;
}

export default async function loadObjects() {
    const url = "/objects/geometry/";
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            let objectList = document.getElementById("object-list");
            objectList.innerHTML = "";  

            data.forEach(obj => {
                let div = document.createElement("div");
                div.className = "object-item d-flex flex-row justify-content-between align-items-center bg-white rounded mb-3 p-2";
                div.style.height = "44px";
                div.innerText = obj.designation;
                div.dataset.objectId = obj.id;
                //objectList.appendChild(div);

                if (hasValidGeometry(obj.geometry)) {
                    let hintSpan = document.createElement("span");
                    hintSpan.className = "drawing-hint";
                    hintSpan.style.fontWeight = "bold";
                    hintSpan.style.color = "green";
                    hintSpan.innerHTML = "✓";
                    // Füge den Hinweis ans Ende des Listeneintrags hinzu
                    div.appendChild(hintSpan);
                }
                
                objectList.appendChild(div);



                if (obj.geometry) {
                    let geometryArray = Array.isArray(obj.geometry) ? obj.geometry : [obj.geometry];

                    geometryArray.forEach(geoData => {
                        try {
                            let geoJsonLayer = L.geoJSON(geoData, {
                                style: { color: 'blue' },
                                onEachFeature: function(feature, layer) {
                                    layer.bindPopup("Name: " + obj.designation);
                                    // WICHTIG: Markiere diese Layer als "bereits gespeichert".
                                    layer.isSaved = true;
                                    // Merke dir, zu welchem Objekt dieser Layer gehört:
                                    layer.objectId = obj.id; 
                                }
                            });
                            drawnItems.addLayer(geoJsonLayer);
                        } catch (e) {
                            console.error("Fehler beim Parsen der Geometrie für Objekt " + obj.id, e);
                        }
                    });
                }
            });
            objectList.addEventListener("click", function(event) {
                let target = event.target.closest(".object-item");
                if (target) {
                    selectObject(target.dataset.objectId);
                }
            });

        } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error fetching objects:', error);
    }
}

let drawnItems;

/********************************************/
/* 2) OBJEKT SELEKTIEREN                    */
/********************************************/
export function selectObject(id){

    document.querySelectorAll('.object-item').forEach(item => {
        item.classList.remove('selected');
    });
    
    const selectedDiv = document.querySelector(`[data-object-id="${id}"]`);
    if (selectedDiv) {
        selectedDiv.classList.add('selected');
    }

    const oldButton = document.querySelector('.save-button');
    if (oldButton) {
        oldButton.remove();
    }

    const oldButtonDelete = document.querySelector('.delete-button');
    if (oldButtonDelete) {
        oldButtonDelete.remove();
    }

    // Container für die Buttons
    const buttonsContainer = document.createElement('div');
    buttonsContainer.className = 'buttons-container d-flex gap-2';
    selectedDiv.appendChild(buttonsContainer);

    // Save Button
    const saveButton = document.createElement('button');
    saveButton.className = 'btn btn-primary btn-sm save-button';
    saveButton.innerHTML = '<i class="fa-solid fa-floppy-disk"></i>';
    saveButton.onclick = function() {
        /*
         * ==========================================================
         *  Nur die NEU gezeichneten Layer (isSaved = false) 
         *  für dieses Objekt id als FeatureCollection speichern
         * ==========================================================
         */
        const newLayers = [];
        drawnItems.eachLayer(function(layer) {
            if (layer.isSaved === false) {                
                newLayers.push(layer.toGeoJSON());
            }
        });

        if (newLayers.length === 0) {
            alert("Keine neuen Geometrien zum Speichern!");
            return;
        }

        // Baue eine FeatureCollection
        const featureCollection = {
            type: "FeatureCollection",
            features: newLayers.map(f => {
                // Falls f schon ein Feature ist, übernimm es
                if (f.type === "Feature") {
                    return f;
                }
                // Sonst wandle es in ein Feature
                return {
                    type: "Feature",
                    geometry: f,
                    properties: {}
                };
            })
        };

        // Jetzt Aufruf der bekannten Funktion
        saveGeometry(id, featureCollection);
    };
    buttonsContainer.appendChild(saveButton);
    
    // Löschen-Button
    const deleteButton = document.createElement('button');
    deleteButton.className = 'btn btn-danger btn-sm delete-button';
    deleteButton.innerHTML = '<i class="fa-solid fa-trash"></i>';
    deleteButton.onclick = function() {
        deleteObject(id);
    };
    buttonsContainer.appendChild(deleteButton);
}

/********************************************/
/* 3) OBJEKT LÖSCHEN                        */
/********************************************/
function deleteObject(id) {
    const url = "/objects/delete-geometry/";
    const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value; 

    fetch(`${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ id: id }),
    })
    .then(response => {
        if (response.ok) {
            console.log(`Objekt mit ID ${id} wurde erfolgreich vom Server gelöscht.`);
            //loadObjects();
        } else {
            console.log('Fehler beim Löschen des Objekts vom Server.');
        }
    })
    .catch(error => {
        console.error('Fehler beim Senden der Lösch-Anfrage:', error);
    });
}



/********************************************/
/* 4) SPEICHERN-FUNKTION                    */
/********************************************/
export function saveGeometry(objectId, geoJson) {
    if (!objectId) {
        alert("Bitte zuerst ein Objekt auswählen!");
        return;
    }

    const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value; 
    const url = "/objects/geometry/";
    fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrftoken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ object_id: objectId, geometry: geoJson })
    })
    .then(response => response.json())
    .then(data => {
        console.log("Erfolgreich gespeichert:", data);

        // (1) Falls OK: Alle Layers neu laden, damit wir 
        // jetzt die aktuellen IDs vom Server haben. 
        // Zuerst die Karte leeren:
        drawnItems.clearLayers();
        loadObjects();

    })
    .catch(error => console.error("Fehler beim Speichern:", error));
}


/********************************************/
/* 5) INITIALISIERUNG DER KARTE             */
/********************************************/

export function initMap() {
    let mapContainer = document.getElementById("map");
    if (!mapContainer) {
        console.error("Karte nicht gefunden!");
        return;
    }

    mapContainer.style.width = "100%";
    mapContainer.style.height = "90vh";

    // Vollständige deutsche Übersetzungen
    L.drawLocal = {
        draw: {
            toolbar: {
                actions: {
                    title: 'Zeichnen abbrechen',
                    text: 'Abbrechen'
                },
                finish: {
                    title: 'Zeichnen beenden',
                    text: 'Beenden'
                },
                undo: {
                    title: 'Letzten Punkt löschen',
                    text: 'Letzten Punkt löschen'
                },
                buttons: {
                    polyline: 'Linie zeichnen',
                    polygon: 'Polygon zeichnen',
                    rectangle: 'Rechteck zeichnen',
                    circle: 'Kreis zeichnen',
                    marker: 'Markierung setzen',
                    circlemarker: 'Kreismarkierung setzen'
                }
            },
            handlers: {
                circle: {
                    tooltip: {
                        start: 'Klicken und ziehen, um einen Kreis zu zeichnen'
                    },
                    radius: 'Radius'
                },
                circlemarker: {
                    tooltip: {
                        start: 'Klicken Sie, um eine Kreismarkierung zu setzen'
                    }
                },
                marker: {
                    tooltip: {
                        start: 'Klicken Sie, um eine Markierung zu setzen'
                    }
                },
                polygon: {
                    tooltip: {
                        start: 'Klicken Sie, um mit dem Zeichnen zu beginnen',
                        cont: 'Klicken Sie, um weitere Punkte hinzuzufügen',
                        end: 'Klicken Sie auf den ersten Punkt, um das Polygon zu schließen'
                    }
                },
                polyline: {
                    error: 'Fehler: Linien dürfen sich nicht überschneiden!',
                    tooltip: {
                        start: 'Klicken Sie, um mit der Linie zu beginnen',
                        cont: 'Klicken Sie, um die Linie fortzusetzen',
                        end: 'Klicken Sie auf den letzten Punkt, um die Linie zu beenden'
                    }
                },
                rectangle: {
                    tooltip: {
                        start: 'Klicken und ziehen Sie, um ein Rechteck zu zeichnen'
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: 'Loslassen, um das Zeichnen zu beenden'
                    }
                }
            }
        },
        edit: {
            toolbar: {
                actions: {
                    save: {
                        title: 'Änderungen speichern',
                        text: 'Speichern'
                    },
                    cancel: {
                        title: 'Bearbeitung abbrechen',
                        text: 'Abbrechen'
                    },
                    clearAll: {
                        title: 'Alles löschen',
                        text: 'Alles löschen'
                    }
                },
                buttons: {
                    edit: 'Bearbeiten',
                    editDisabled: 'Keine Objekte zum Bearbeiten',
                    remove: 'Löschen',
                    removeDisabled: 'Keine Objekte zum Löschen'
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: 'Ziehen Sie die Punkte oder Marker zum Bearbeiten',
                        subtext: 'Klicken Sie auf Abbrechen, um die Änderungen rückgängig zu machen'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Klicken Sie auf ein Element zum Löschen'
                    }
                }
            }
        }
    };

    let map = L.map('map', {
        center: [47.5162, 14.5501],
        zoom: 7,
        maxZoom: 19,
        zoomControl: true
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        tileSize: 256,
        zoomOffset: 0,
        attribution: '&copy; OpenStreetMap Mitwirkende'
    }).addTo(map);
    L.tileLayer('http://localhost:8080/tiles/{z}/{x}/{y}.png', {
        attribution: 'Mein eigenes Kartenmaterial'
    }).addTo(map);

    
    drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    let drawControl = new L.Control.Draw({
        edit: {
            featureGroup: drawnItems,
            edit: {
                title: "Geometrie bearbeiten",
                tooltip: "Klicken Sie zum Bearbeiten der Form"
            },
            remove: {
                title: "Geometrie löschen",
                tooltip: "Klicken Sie zum Löschen der Form"
            }
        },
        draw: {
            polygon: {
                shapeOptions: { color: 'blue' },
                showArea: true,
                allowIntersection: false,
                title: "Polygon zeichnen",
                tooltip: {
                    start: "Klicken Sie, um mit dem Zeichnen zu beginnen",
                    cont: "Klicken Sie, um weitere Punkte hinzuzufügen",
                    end: "Klicken Sie auf den ersten Punkt, um das Polygon zu schließen"
                }
            },
            polyline: {
                shapeOptions: { color: 'red' },
                title: "Linie zeichnen",
                tooltip: {
                    start: "Klicken Sie, um mit der Linie zu beginnen",
                    cont: "Klicken Sie, um die Linie fortzusetzen",
                    end: "Klicken Sie auf den letzten Punkt, um die Linie zu beenden"
                }
            },
            rectangle: {
                shapeOptions: { color: 'green' },
                title: "Rechteck zeichnen",
                tooltip: {
                    start: "Klicken und ziehen Sie, um ein Rechteck zu zeichnen"
                }
            },
            circle: false,
            marker: {
                title: "Markierung setzen",
                tooltip: {
                    start: "Klicken Sie, um eine Markierung zu setzen"
                }
            }
        }
    });
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, function (event) {
        let layer = event.layer;
        // Markiere neue Layer als "noch nicht gespeichert"
        layer.isSaved = false;
        drawnItems.addLayer(layer);
    });

    setTimeout(() => {
        map.invalidateSize();
    }, 300);

    /***********************************************/
    /* HINZUFÜGEN: SUCH-FUNKTION ÜBER GEOCODER     */
    /***********************************************/
    // Erstelle z.B. einen Geocoder (Nominatim als Standard)
    let geocoder = L.Control.Geocoder.nominatim();
    L.Control.geocoder({
        placeholder: 'Adresse oder Ort eingeben...',
        defaultMarkGeocode: false,
        geocoder: geocoder,
        collapsed: false,
        position: 'topright'
    })
    .on('markgeocode', function(e) {
        let center = e.geocode.center;
        map.setView(center, 16);
        L.marker(center).addTo(map);
    })
    .addTo(map);

    setTimeout(() => {
        let container = document.querySelector('.leaflet-control-geocoder');
        let form = document.querySelector('.leaflet-control-geocoder-form');
        let input = document.querySelector('.leaflet-control-geocoder-form input');
        let oldButton = document.querySelector('.leaflet-control-geocoder-icon');
    
        if (container) container.classList.add("d-flex", "align-items-center", "p-2", "rounded", "bg-white");
        if (form) form.classList.add("d-flex", "gap-3");
        if (input) {
            input.classList.add("form-control", "form-control-sm");
            input.style.width = "220px";
            input.placeholder = "Adresse eingeben...";
        }
        if (oldButton) {
            let newButton = document.createElement("button");
            newButton.classList.add("btn", "btn-primary", "btn-sm", "d-flex", "align-items-center", "me-2");
            newButton.innerHTML = 'Suchen';
            newButton.type = "button";
            newButton.addEventListener('click', function(ev) {
                ev.preventDefault();
                let query = input.value.trim();
                fetch(`https://nominatim.openstreetmap.org/search?q=${query}&limit=5&format=json&addressdetails=1`)
                    .then(response => response.json())
                    .then(results => {
                        if (results && results.length > 0) {
                            let result = results[0];
                            map.setView([result.lat, result.lon], 16);
                            L.marker([result.lat, result.lon]).addTo(map);
                        }
                    });
            });

            input.addEventListener('keydown', function(e) {
                if (e.key === "Enter") {
                    e.preventDefault();
                    newButton.click();
                }
            });
            
        
            oldButton.replaceWith(newButton);
        }

        let alternativeText = document.querySelector('.leaflet-control-geocoder-alternatives');
        if (alternativeText) alternativeText.remove();

        let errorText = document.querySelector('.leaflet-control-geocoder-form-no-error');
        if (errorText) errorText.remove();

    }, 200);

}